import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In May 2012 Colin visited Iceland again, this time with his two sons Richard and
Alexander. An  earlier visit, as a student in 1966, was an early influence in
his decision to study earth sciences. `}</p>
    <p>{`The brief trip enabled the three to visit most of the tourist attractions in the
SW of the  country. Included was a tour of a geothermally-powered combined
electric power and district  heating facility that delivers electricity and hot
water to large parts of Reykjavik at very low cost.  They also enjoyed the
coastline, the geysers, the waterfalls and the snow-capped glaciers.  `}</p>
    <p>{`Nearer to home, in The Hague, a geothermally-powered district heating system for
5000 homes is  being officially inaugurated today, in the vanguard of many more
schemes planned for The  Netherlands in the coming years. 'Geothermal' is
clearly a development to watch as a geoscience based contribution to future
energy needs. `}</p>
    <p>{`Photo below: The geyser Strokur erupts regularly `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      